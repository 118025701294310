<template>
  <div class="text-black flex">
    <Sidebar />
    <div class="font-semibold flex-1 h-screen">
      <Navbar title="Settings" />
      <div id="settings-content" class="p-7">
        <div
          class="w-full mt-2 py-10 text-black bg-nColorFillGray border border-nColorStrokeGray rounded-xl"
          v-if="profile.settings.notifications"
        >
          <h1 class="font-bold text-2xl px-10">Notification Settings</h1>
          <div
            v-for="item in profile.settings.notifications"
            :key="item.slug"
            class="mx-10 mt-10 flex gap-4 items-center"
          >
            <Toggle v-model="item.status" />
            <div>
              <p class="font-semibold text-lg">{{ item.name }}</p>
              <p class="font-normal text-base">{{ item.description }}</p>
            </div>
          </div>
        </div>
        <div class="flex place-content-end mt-4">
          <div v-show="errorMsg" class="text-red-500 place-self-end p-3">
            <p class="text-md font-semibold">
              {{ errorMsg }}
            </p>
          </div>
          <div
            v-if="successMsg"
            class="success text-green-500 place-self-end p-1"
          >
            <p class="text-base font-semibold">
              {{ successMsg }}
            </p>
          </div>
          <div>
            <ButtonInput
              @click.prevent="saveNotificationSettings()"
              buttonText="Save"
              :loading="loading"
              :buttonClass="
                'transition shadow duration-300 ease-out tracking-wider bg-green-500 text-white px-8 py-2 rounded-lg uppercase text-base font-semibold ml-4 outline-none focus:outline-none'
              "
              :loadingClass="
                'cursor-not-allowed transition shadow duration-300 ease-out tracking-wider bg-green-500 text-white px-8 py-2 rounded-lg uppercase font-bold ml-4 outline-none focus:outline-none'
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/Common/Sidebar/Standard";
import Navbar from "@/components/Common/Navbar/Standard";
import { mapGetters } from "vuex";
import Toggle from "@vueform/toggle";
import ButtonInput from "@/components/Elements/ButtonInput.vue";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  name: "Settings",
  components: { Sidebar, Navbar, Toggle, ButtonInput },
  computed: {
    ...mapGetters(["profile"]),
    locationString() {
      if (this.profile.locations) {
        return this.profile.locations.join(", ");
      }
      return "";
    },
  },
  data() {
    return {
      loading: false,
      successMsg: null,
      errorMsg: null,
    };
  },
  mounted() {},
  methods: {
    saveNotificationSettings() {
      ApiService.post(apiResource.settings.saveNotificationSettings, {
        settings: this.profile.settings.notifications,
      })
        .then(({ data }) => {
          this.successMsg = data.msg;
        })
        .catch((error) => {
          this.errorMsg = error.response.data.msg;
        });
    },
  },
};
</script>
<style src="@vueform/toggle/themes/default.css"></style>
<style scoped lang="postcss">
.two-col-ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
</style>
